"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.TYPE_CONTENT_DEFINE = exports.STORE_TYPE = exports.PARAMS = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = void 0;
var STORE_TYPE = exports.STORE_TYPE = {
  FOOD: 'Thức ăn',
  DRINK: 'Thức uống'
};
var ORDER_FOOD_STATUS = exports.ORDER_FOOD_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ',
  DONE: 'Hoàn thành',
  NO_DELIVERY: 'Không giao'
};
var ORDER_DELIVERY_STATUS = exports.ORDER_DELIVERY_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ'
};
var ORDER_TRANSPORT_STATUS = exports.ORDER_TRANSPORT_STATUS = {
  WAITING: 'Đang đợi',
  DELIVERING: 'Đang giao',
  COMPLETE: 'Hoàn thành',
  CANCEL: 'Huỷ'
};
var TYPE_CONTENT_DEFINE = exports.TYPE_CONTENT_DEFINE = {
  ABOUT: 'Thông tin chúng tôi',
  FAQ: 'Câu hỏi thường gặp',
  HOW_TO_USE: 'Cách sử dụng',
  SECURITY: 'Bảo mật',
  TERM_CONDITION: 'Điều khoản',
  HOW_TO_DEPOSIT: 'Hướng dẫn nạp điểm',
  HOW_TO_USE_DRIVER: 'Cách sử dụng (Tài xế)',
  FAQ_DRIVER: 'Câu hỏi thường gặp (Tài xế)'
};
var ORDER_TYPE = exports.ORDER_TYPE = {
  FOOD: 'Thức ăn',
  TRANSPORT: 'Di chuyển',
  DELIVERY: 'Giao hàng'
};
var PARAMS = exports.PARAMS = {
  COMMISSION_FOOD: 'Thức ăn',
  COMMISSION_DELIVERY: 'Giao hàng',
  COMMISSION_TRANSPORT: 'Di chuyển '
};
var tagOptions = exports.tagOptions = {
  WAITING: {
    type: 'warning',
    effect: 'plain'
  },
  DELIVERING: {
    type: '',
    effect: 'plain'
  },
  COMPLETE: {
    type: 'success',
    effect: 'dark'
  },
  CANCEL: {
    type: 'danger',
    effect: 'dark'
  },
  DONE: {
    type: 'success',
    effect: 'dark'
  },
  NO_DELIVERY: {
    type: 'danger',
    effect: 'dark'
  }
};