"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var order = _interopRequireWildcard(require("@/api/order/food"));
var driver = _interopRequireWildcard(require("@/api/driver"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _OrderFoodDialog = _interopRequireDefault(require("./../components/OrderFoodDialog"));
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
var _DetailFoodModal = _interopRequireDefault(require("./components/DetailFoodModal.vue"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    OrderFoodDialog: _OrderFoodDialog.default,
    DetailFoodModal: _DetailFoodModal.default
  },
  props: {
    driverId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      totalMoneyTotal: 0,
      totalRevenue: 0,
      totalIncome: 0,
      ORDER_FOOD_STATUS: _enum.ORDER_FOOD_STATUS,
      listLoading: false,
      total: 0,
      totalMoney: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null,
        status: null
      },
      tableData: [],
      date: [(0, _moment.default)().subtract(7, "days").startOf("day").toDate(), (0, _moment.default)().endOf("day").toDate()],
      tagOptions: _enum.tagOptions,
      datePicker: {
        minDate: null,
        maxDate: null
      },
      pickerOptions: {
        disabledDate: this.disabledDueDate,
        onPick: this.handleChangeDatePicker
      }
    };
  },
  created: function created() {
    if (!this.driverId) {
      this.getTableData();
    } else {
      this.getTableDataByDriverId();
    }
  },
  watch: {
    driverId: function driverId() {
      this.getTableDataByDriverId();
    }
  },
  methods: {
    calcSumPrice: function calcSumPrice(data) {
      var sum = data.reduce(function (prev, cur) {
        return prev + cur.amount * cur.finalPrice;
      }, 0);
      return sum;
    },
    viewStore: function viewStore(store) {
      this.$refs["store-dialog"].handleEdit(store);
    },
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    disabledDueDate: function disabledDueDate(time) {
      if (this.datePicker.minDate) {
        var past60days = (0, _moment.default)(this.datePicker.minDate).subtract(60, "days").valueOf();
        var next60days = (0, _moment.default)(this.datePicker.minDate).add(60, "days").valueOf();
        return time.getTime() < past60days || time.getTime() > next60days;
      }
      return false;
    },
    handleChangeDatePicker: function handleChangeDatePicker(date) {
      this.datePicker = date;
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this = this;
      driver.orderFood(this.driverId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this2 = this;
      this.listLoading = true;
      var data = {
        orderFood: {
          status: row.status
        }
      };
      order.update(row.id, data).then(function () {
        _this2.$notify({
          type: "success",
          message: "Thành công"
        });
        _this2.getTableData();
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    detailFood: function detailFood(order) {
      this.$refs["detail-food"].handleOpen(order);
    },
    getTotalMoney: function getTotalMoney() {
      var _this3 = this;
      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this3.totalMoney = total;
      });
    },
    getSummary: function getSummary() {
      var _this4 = this;
      order.getSummary(this.listQuery).then(function (res) {
        _this4.totalRevenue = res.data.totalRevenue;
        _this4.totalIncome = res.data.totalIncome;
        _this4.totalMoneyTotal = res.data.totalMoneyTotal;
      });
    },
    getTableData: function getTableData() {
      var _this5 = this;
      this.listLoading = true;
      this.listQuery.from = this.date ? this.date[0] : undefined;
      this.listQuery.to = this.date ? this.date[1] : undefined;
      this.getSummary();
      order.findAll(this.listQuery).then(function (res) {
        _this5.tableData = res.data.data;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};