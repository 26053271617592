var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm code, tên, sđt",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "daterange",
              "range-separator": "To",
              format: "dd/MM/yyyy HH:mm",
              "value-format": "yyyy-MM-dd HH:mm",
              "start-placeholder": "Ngày bắt đầu",
              "end-placeholder": "Ngày kết thúc",
              "picker-options": _vm.pickerOptions,
            },
            on: { change: _vm.getTableData },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("\n    Tổng doanh thu:\n    "),
        _c("span", { staticClass: "increase" }, [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.totalMoneyTotal))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "order-food-box" },
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "food",
              on: {
                click: function ($event) {
                  return _vm.detailFood(item)
                },
              },
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "price" },
                    [
                      _vm._v(_vm._s(item.code) + "\n            "),
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            size: "mini",
                            type: _vm.tagOptions[item.status].type,
                            effect: _vm.tagOptions[item.status].effect,
                          },
                        },
                        [_vm._v(_vm._s(_vm.ORDER_FOOD_STATUS[item.status]))]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { margin: "8px 0" } }, [
                  _c("span", { staticClass: "label-price" }, [
                    _vm._v("Tài xế:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(
                      _vm._s(
                        item.driver &&
                          item.driver.name + " - " + item.driver.phone
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "label-price" }, [
                    _vm._v("Tổng tiền:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.formatCurrency(item.moneyTotal))),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "order-food-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Code", prop: "code", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _vm._v(" "),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].type,
                          effect:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ORDER_FOOD_STATUS[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Tài xế", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticStyle: {
                          "list-style": "none",
                          "padding-left": "0",
                        },
                      },
                      [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Tên:")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(row.driver ? row.driver.name : "Chưa có")
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticStyle: {
                          "list-style": "none",
                          "padding-left": "0",
                        },
                      },
                      [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Tên:")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.customer.name))]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Địa chỉ nhận hàng:"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(row.startAddress))]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              width: "200",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.detailFood(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("toThousandFilter")(row.totalFood)
                              ) + " món"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tổng cộng:"),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("toThousandFilter")(row.moneyTotal))
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Ngày tạo",
              prop: "dateCreated",
              align: "right",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatDateTime(row.dateCreated)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        staticClass: "order-pagination",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("order-food-dialog", { ref: "dialog" }),
      _vm._v(" "),
      _c("DetailFoodModal", { ref: "detail-food" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }