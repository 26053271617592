var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "food-list" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm tên danh mục",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "food-box" },
        _vm._l(_vm.tableData, function (item, index) {
          return _c("div", { key: index, staticClass: "food" }, [
            _c(
              "div",
              {
                staticStyle: { width: "100%", height: "100%", display: "flex" },
                on: {
                  click: function ($event) {
                    return _vm.handleEdit(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "info" }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button-custom",
                on: {
                  click: function ($event) {
                    return _vm.handleBlock(item)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-delete text-danger icon-custom ",
                }),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "name",
              label: "Tên danh mục",
              align: "center",
              width: "270",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Chi tiết")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleBlock(row)
                          },
                        },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        staticClass: "order-pagination",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("menu-food-dialog", {
        ref: "dialog",
        attrs: { storeId: _vm.storeId },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }