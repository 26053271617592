var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết đơn hàng",
        visible: _vm.visible,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "ul",
        { staticClass: "food-list" },
        _vm._l(_vm.order.details, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "food-item",
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c("div", [
                _c("div", { staticClass: "product-order" }, [
                  _c("span", { staticClass: "amount quantity-badge" }, [
                    _vm._v(" x" + _vm._s(item.amount)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "product-name" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.food.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("span", { staticClass: "single-price" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toThousandFilter")(item.food.finalPrice)
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    item.orderFoodVariationDetails.length
                      ? _c(
                          "div",
                          _vm._l(
                            item.orderFoodVariationDetails,
                            function (detail) {
                              return _c("div", { key: detail.id }, [
                                _c("span", [
                                  _vm._v(_vm._s(detail.foodVariation.name)),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v("x " + _vm._s(detail.quantity)),
                                ]),
                              ])
                            }
                          ),
                          0
                        )
                      : _c(
                          "div",
                          _vm._l(item.foodVariations, function (detail) {
                            return _c("div", { key: detail.id }, [
                              _c("span", [_vm._v(_vm._s(detail.name))]),
                            ])
                          }),
                          0
                        ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "price" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toThousandFilter")(_vm.calcProductItem(item))
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-bottom": "10px",
                      visibility: "hidden",
                    },
                  },
                  [
                    _c("span", { staticClass: "single-price" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toThousandFilter")(_vm.calcProductItem(item))
                        )
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                item.orderFoodVariationDetails.length
                  ? _c(
                      "div",
                      _vm._l(item.orderFoodVariationDetails, function (detail) {
                        return _c("div", { key: detail.id }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toThousandFilter")(
                                  detail.price * detail.quantity
                                )
                              )
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c(
                      "div",
                      _vm._l(item.foodVariations, function (detail) {
                        return _c("div", { key: detail.id }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toThousandFilter")(detail.price))
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }