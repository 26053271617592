"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.remove = remove;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/menuFood",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/menuFood",
    method: "post",
    data: data
  });
}
function update(foodId, data) {
  return (0, _request.default)({
    url: "/menuFood/".concat(foodId, "/update"),
    method: "post",
    data: data
  });
}
function remove(foodId) {
  return (0, _request.default)({
    url: "/menuFood/".concat(foodId, "/delete"),
    method: "post"
  });
}