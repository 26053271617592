var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm món ăn" : "Cập nhật món ăn",
        visible: _vm.dialogVisible,
        width: "600px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card", "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "Thông tin", name: "info" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dialogLoading,
                    expression: "dialogLoading",
                  },
                ],
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      disabled: _vm.dialogDisabled,
                      rules: _vm.rules,
                      model: _vm.tempData,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Tên món", prop: "name" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "name", $$v)
                                    },
                                    expression: "tempData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Danh mục",
                                  prop: "menuFoodId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "",
                                      remote: "",
                                      "remote-method": function (search) {
                                        _vm.menuFoodListQuery.search = search
                                        _vm.fetchCategory()
                                      },
                                    },
                                    model: {
                                      value: _vm.tempData.menuFoodId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "menuFoodId",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.menuFoodId",
                                    },
                                  },
                                  _vm._l(_vm.menuFoodList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Thành phần",
                                  prop: "ingredient",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.ingredient,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "ingredient", $$v)
                                    },
                                    expression: "tempData.ingredient",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Giá bán", prop: "finalPrice" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tempData.finalPrice,
                                      expression: "tempData.finalPrice",
                                    },
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: {
                                        alias: "decimal",
                                        groupSeparator: ".",
                                        rightAlign: false,
                                        autoGroup: true,
                                        repeat: 20,
                                        autoUnmask: true,
                                        allowMinus: false,
                                      },
                                      expression:
                                        "{\n                    alias: 'decimal',\n                    groupSeparator: '.',\n                    rightAlign: false,\n                    autoGroup: true,\n                    repeat: 20,\n                    autoUnmask: true,\n                    allowMinus: false,\n                  }",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.tempData.finalPrice },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.tempData,
                                        "finalPrice",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-form-item", { attrs: { label: "Vị trí" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempData.position,
                                    expression: "tempData.position",
                                  },
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.tempData.position },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.tempData,
                                      "position",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.type != "OTHER"
                          ? _c(
                              "el-col",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "", prop: "thumbnail" } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          disabled: _vm.dialogDisabled,
                                          name: "file",
                                          accept: "image/jpeg, image/png",
                                          drag: "",
                                          "before-upload": _vm.beforeUpload,
                                          action:
                                            _vm.baseUrl +
                                            "/food/upload/thumbnail",
                                          "on-success": _vm.handleUploadSuccess,
                                          "show-file-list": false,
                                          headers: {
                                            token: _vm.token,
                                            version: _vm.$version,
                                          },
                                        },
                                      },
                                      [
                                        !_vm.tempData.thumbnail
                                          ? [
                                              _c("i", {
                                                staticClass: "el-icon-upload",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-upload__text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Kéo thả vào đây hoặc\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      "click vào để upload"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          : _c("img", {
                                              staticClass: "avatar",
                                              attrs: {
                                                src: _vm.$url(
                                                  _vm.tempData.thumbnail
                                                ),
                                                alt: "",
                                              },
                                            }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Size/Topping", name: "variation" } },
                [
                  _c("variant", {
                    attrs: { food: _vm.tempData },
                    on: { updateSuccess: _vm.onUpdateSuccess },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "OTHER"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Gallery", name: "gallery" } },
                [
                  _c("gallery", {
                    ref: "gallery",
                    attrs: { form: _vm.tempData },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm._v(" "),
          !_vm.dialogDisabled && _vm.activeName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }