var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết đơn hàng",
        visible: _vm.dialogVisible,
        width: "auto",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "ul",
        { staticClass: "food-list" },
        _vm._l(_vm.formData.details, function (item, index) {
          return _c("li", { key: index, staticClass: "food-item" }, [
            _c("div", [
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(item.food.name)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "amount" }, [
                _vm._v(" x " + _vm._s(item.amount)),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "single-price" }, [
                  _vm._v(
                    _vm._s(_vm._f("toThousandFilter")(item.food.finalPrice))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "price" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("toThousandFilter")(item.amount * item.finalPrice)
                  )
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { "text-align": "right" } }, [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tổng cộng:")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("toThousandFilter")(
                _vm.calcSumPrice(_vm.formData.details || [])
              )
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }