exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#44566c",
	"menuActiveText": "#1596d2",
	"subMenuActiveText": "#44566c",
	"menuBg": "#fff",
	"menuHover": "linear-gradient(rgba(244,102,62,0.8), #F4663E)",
	"subMenuBg": "#fff",
	"subMenuHover": "linear-gradient(rgba(244,102,62,0.8), #F4663E)",
	"sideBarWidth": "240px"
};