var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "food-list" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm tên món ăn",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "food-box" },
        _vm._l(_vm.tableData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "food",
              on: {
                click: function ($event) {
                  return _vm.handleEdit(item)
                },
              },
            },
            [
              _c("img", {
                staticClass: "image",
                attrs: { src: _vm.$url(item.thumbnail), alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.name)),
                  item.isBlock
                    ? _c("span", {
                        staticClass: "el-icon-lock",
                        staticStyle: {
                          color: "red",
                          display: "inline-block",
                          "margin-left": "5px",
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "label-price" }, [
                    _vm._v("Giá gốc:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.formatCurrency(item.originPrice))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "label-price" }, [
                    _vm._v("Giá sau khuyến mãi:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.formatCurrency(item.finalPrice))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "drop-down",
                  on: {
                    click: function (e) {
                      return e.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          !item.isBlock
                            ? _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    icon: "el-icon-lock",
                                    command: item,
                                  },
                                },
                                [_vm._v("Khóa")]
                              )
                            : _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: item,
                                    icon: "el-icon-unlock",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUnBlock(item)
                                    },
                                  },
                                },
                                [_vm._v("Mở khóa")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "food-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "name",
              label: "Tên món",
              align: "center",
              width: "270",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "image",
                        attrs: { src: _vm.$url(row.thumbnail), alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Danh mục:")]),
                      _vm._v(" " + _vm._s(row.menuFood && row.menuFood.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("b", [_vm._v("Thành phần:")]),
                      _vm._v(" " + _vm._s(row.ingredient)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Giá bán:")]),
                      _vm._v(" " + _vm._s(_vm.formatCurrency(row.finalPrice))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Vị trí", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      on: {
                        change: function ($event) {
                          return _vm.editData(row)
                        },
                      },
                      model: {
                        value: row.position,
                        callback: function ($$v) {
                          _vm.$set(row, "position", $$v)
                        },
                        expression: "row.position",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "auto",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Chi tiết")]
                    ),
                    _vm._v(" "),
                    !row.isBlock
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleBlock(row)
                              },
                            },
                          },
                          [_vm._v("Khóa")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUnBlock(row)
                              },
                            },
                          },
                          [_vm._v("Mở khóa")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        staticClass: "order-pagination",
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm._v(" "),
      _c("food-dialog", {
        ref: "dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }