"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _enum = require("@/utils/enum");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      STORE_TYPE: _enum.STORE_TYPE,
      loading: false,
      form: (0, _objectSpread2.default)({}, this.$store.state.user.info),
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên cửa hàng",
          trigger: "submit"
        },
        address: {
          required: true,
          message: "Vui lòng nhập địa chỉ",
          trigger: "submit"
        },
        // type: {
        //   required: true,
        //   trigger: "submit",
        //   message: "Vui lòng chọn loại cửa hàng"
        // },
        openTime: {
          required: true,
          message: "Vui lòng chọn giờ mở cửa",
          trigger: "submit"
        },
        closeTime: {
          required: true,
          message: "Vui lòng chọn giờ đóng cửa",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    handleUpdate: function handleUpdate() {
      var _this = this;
      var data = {
        store: this.form
      };
      this.loading = true;
      (0, _user.updateProfile)(data).then(function () {
        _this.$notify({
          type: "success",
          title: "Thông báo",
          message: "Cập nhật thành công"
        });
        _this.$store.dispatch("user/getInfo");
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};