"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _store = _interopRequireDefault(require("@/store"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PanThumb: _PanThumb.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    user: function user(state) {
      return state.user.info;
    }
  })),
  data: function data() {
    return {
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  }
};