"use strict";

var _interopRequireDefault = require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/mac/Documents/project/050/050store/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _enum = require("@/utils/enum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      STATUS_ORDER: _enum.STATUS_ORDER,
      btnLoading: false
    };
  },
  methods: {
    handleView: function handleView(order) {
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, order);
    },
    calcSumPrice: function calcSumPrice(data) {
      var sum = data.reduce(function (prev, cur) {
        return prev + cur.amount * cur.finalPrice;
      }, 0);
      return sum;
    }
  }
};